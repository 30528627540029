/* Featured section */
.featured-blog {
    @apply flex md:flex-row flex-col;
}

.featured-blog-heading {
    @apply flex items-center;
}

.featured-blog-img {
    @apply flex lg:w-[46%] md:w-[45%] w-[100%];
}

.featured-blog-content {
    @apply flex lg:w-[54%] md:w-[55%] w-[100%] flex-col;
}

.featured-blog-content-date {
    @apply flex items-center opacity-60;
}

.featured-blog-content-author {
    @apply flex items-center mt-3 text-[17px] md:text-[15px] lg:text-[16px] xl:text-[18px] font-medium uppercase;
}

.featured-blog-content-author span {
    @apply opacity-70;
}

.featured-blog-content-ratings {
    @apply flex items-center mt-4 md:mt-5 xl:mt-6 gap-1 text-[20px] md:text-[18px] xl:text-[22px]
}

.featured-blog-content-ratings span {
    @apply text-[17px] md:text-[15px] xl:text-[18px] ml-2 font-medium;
}