/* Toast css */
.toast-container {
    @apply z-40 fixed top-0 left-0 flex justify-center items-center w-[100%] h-[100%] bg-colorBgModal text-colorWhite fade-in;
}

.toast {
    @apply h-auto flex justify-center items-center text-[16px] xl:text-[18px] font-normal capitalize bg-colorTertiary text-colorBlack px-6 py-3 xl:py-4 rounded-md;
}

.toast-icon {
    @apply mr-2;
}

.toast-icon-success {
    @apply text-colorSecondary text-[22px] xl:text-[28px];
}

.toast-icon-failure {
    @apply text-colorDanger text-[22px] xl:text-[28px];
}
.toast-icon-warning {
    @apply text-colorAccent text-[22px] xl:text-[28px];
}
.toast-icon-loader {
    @apply text-colorSecondary text-[22px] xl:text-[28px] animate-spin;
}