@import url('./home/index.css');
@import url('./blog/create.css');
@import url('./category/create.css');
@import url('./cookie-policy/index.css');
@import url('./profile/index.css');
@import url('./password-change/index.css');
@import url('./privacy-policy/index.css');

/* Dashboard Main common css for blog, category & user */
.dashboard-main-wrapper {
    @apply w-full flex flex-col gap-10;
}

.dashboard-main-heading {
    @apply flex items-center gap-3;
}

.dashboard-main-heading-icon {
    @apply text-colorSecondary xl:text-[28px] md:text-[24px] text-[26px];
}

.dashboard-main-content {
    @apply flex flex-col gap-6
}

.dashboard-main-content-header {
    @apply flex flex-col md:flex-row justify-between gap-6 md:gap-0;
}

.dashboard-main-content-header-left {
    @apply flex items-center md:justify-start justify-between gap-1 md:gap-6;
}

.dashboard-main-content-header-right {
    @apply flex items-center md:justify-start justify-between gap-1 md:gap-10;
}

.dashboard-main-content-data {
    @apply flex flex-col gap-2
}