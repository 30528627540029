.blog-comment-list-item {
    @apply flex w-full gap-2 border-b-[0.5px] py-4 md:py-6 border-b-colorDivider;
}

.blog-comment-details {
    @apply flex w-full flex-col gap-6 md:gap-5;
}

.blog-comment-text {
    @apply -mt-6 md:-mt-5 xl:-mt-4 text-[16px] xl:text-[18px] font-light tracking-wider leading-[25px] md:leading-6;
}

.blog-comment-avatar {
    @apply flex flex-none flex-col items-center gap-2;
}

.blog-comment-avatar span{
    @apply w-[0.5px] h-full bg-colorDivider;
}

.blog-comment-avatar img {
    @apply w-[30px] h-[30px] xl:w-[35px] xl:h-[35px] rounded-[50%] border-[1px] border-colorDivider;
}

.blog-comment-username {
    @apply flex gap-6 items-center h-[30px] xl:h-[35px] text-[15px] md:text-[14px] xl:text-[16px] uppercase font-medium;
}

.blog-comment-footer {
    @apply flex flex-col-reverse md:flex-row gap-4 md:gap-0 justify-between items-start md:items-center mt-0 md:mt-2;
}

.blog-comment-date {
    @apply font-normal text-[15px] md:text-[14px] xl:text-[16px] opacity-60;
}

.blog-comment-reply-input {
    @apply w-full md:w-[60%] mt-2;
}

.blog-comment-reply-input form {
    @apply w-full;
}

.blog-comment-reply-input textarea {
    @apply relative px-4;
}

.blog-comment-reply-input-btn {
    @apply absolute flex gap-3 bottom-[15px] right-[20px]
}

.blog-comment-reply-input-btn button {
    @apply px-2 py-1 text-[12px] xl:text-[16px] rounded-sm capitalize duration-300;
}

.reply-btn-cancel {
    @apply bg-colorTertiary text-colorBlack;
}

.reply-btn-submit {
    @apply bg-colorPrimary text-colorTextWhite hover:bg-colorSecondary;
}

.blog-comment-footer-reply {
    @apply flex gap-5 mb-2;
}

.blog-comment-footer-view-reply {
    @apply flex gap-2 items-center px-2 xl:px-2.5 py-1 xl:py-1.5 bg-colorTertiary font-normal text-[14px] xl:text-[16px] border-[0.5px] border-colorDivider duration-300 cursor-pointer capitalize rounded-sm;
}

.blog-comment-footer-write-reply {
    @apply flex gap-2 items-center font-normal text-[16px] md:text-[14px] xl:text-[16px] opacity-70 hover:opacity-100 duration-300 cursor-pointer capitalize;
}

.blog-comment-reply {
    @apply flex gap-2 mb-0 md:mb-1 fade-in;
}

.blog-comment-reply-details {
    @apply flex flex-col gap-6 md:gap-5;
}

.blog-comment-reply-avatar {
    @apply flex flex-none flex-col items-center gap-2;
}

.blog-comment-reply-avatar span {
    @apply w-[0.5px] h-full bg-colorDivider;
}

.blog-comment-reply-avatar img {
    @apply w-[30px] h-[30px] xl:w-[35px] xl:h-[35px] rounded-full border-[1px] border-colorDivider ;
}

.blog-comment-reply-username {
    @apply flex gap-6 items-center h-[30px] xl:h-[35px] text-[15px] md:text-[14px] xl:text-[16px] uppercase font-medium;
}

.blog-comment-reply-text {
    @apply -mt-6 md:-mt-5 xl:-mt-4 text-[16px] xl:text-[18px] font-light tracking-wider leading-[25px] md:leading-6;
}

.blog-comment-reply-date {
    @apply font-normal text-[15px] md:text-[14px] xl:text-[16px] opacity-60;
}
