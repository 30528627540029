.preview-content-category-image{
    @apply flex w-full items-center flex-col; 
}

.preview-content-category-image-title {
    @apply flex w-full justify-between;
}

.preview-content-category-image span {
    @apply bg-colorPrimary text-colorTextWhite capitalize py-2 px-4 rounded-sm;
}

.preview-content-category-action {
    @apply flex gap-5;
}

.preview-content-category-edit {
    @apply flex items-center gap-2 bg-colorPrimary text-colorTextWhite capitalize py-2 px-4 rounded-md hover:opacity-70 duration-300;
}

.preview-content-category-delete {
    @apply flex items-center gap-2 bg-colorDanger text-colorTextWhite capitalize py-2 px-4 rounded-md hover:opacity-70 duration-300;
}

.preview-content-category-image img {
    @apply w-[60%];
}

.preview-content-category-details-wrapper {
    @apply px-4 py-4 mt-6 bg-colorWhite card-shadow rounded-md;
}

.preview-content-category-details-heading {
    @apply py-2 mb-5 capitalize font-normal border-b-[1.5px] border-b-colorBorder rounded-sm;
}

.preview-content-category-detail {
    @apply flex w-full my-3 capitalize;
}

.preview-content-category-detail-left {
    @apply flex items-start w-[12%];
}

.preview-content-category-detail-left span {
    @apply w-full px-4 py-2 bg-colorPrimary text-colorTextWhite rounded-sm;
}

.preview-content-category-detail-right {
    @apply flex flex-1 items-center px-6 py-1;
}