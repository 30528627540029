    /* Page Not Found Section */
	.not-found-container {
		@apply w-full h-[100vh] flex flex-col justify-center items-center md:gap-2 gap-4 md:px-0 px-20;
	}

	.not-found-code {
		@apply xl:text-[180px] lg:text-[150px] md:text-[120px] text-[105px] font-extrabold;
	}

	.not-found-title {
		@apply xl:text-[36px] lg:text-[32px] md:text-[26px] text-[24px] md:-mt-6 -mt-7 uppercase font-semibold;
	}

	.not-found-msg {
		@apply text-center xl:text-[18px] text-[16px] capitalize leading-6;
	}

	.not-found-home-link {
		@apply flex gap-1 xl:text-[18px] text-[16px] md:mt-6 mt-4 hover:text-colorAccent items-center uppercase ease-linear duration-200;
	}
	/* End Of Page Not Found */