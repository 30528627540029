.table-search-wrapper {
    @apply relative
}

.table-search-input {
    @apply w-full px-4 xl:px-6 py-2.5 lg:py-2 xl:py-2 xxl:py-2.5 text-[14px] xl:text-[16px] border-[0.5px] border-colorDivider ease-linear font-light rounded-md;
}

.table-search-input:focus {
    @apply outline outline-1 outline-colorDivider;
}

.table-search-input::placeholder {
    @apply text-[14px] xl:text-[16px] font-light capitalize;
}

.table-search-input-icon {
    @apply absolute right-2 md:right-3 top-0 bottom-0 my-auto xl:text-[22px] text-[18px] opacity-40 ease-linear duration-200;
}

.table-search-input:focus + .table-search-input-icon {
    @apply opacity-75 text-colorBlack;
}