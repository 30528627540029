/* Recent blog main card */
.recent-blog-main-card-img {
    @apply overflow-hidden;
}

.recent-blog-main-card-img-container {
    @apply relative;
}

.recent-blog-main-card-category-tag {
    @apply absolute xl:top-4 lg:top-3 md:top-2  top-3  text-colorTextWhite font-sans cursor-pointer;
}

.recent-blog-main-card-category-tag-border {
    @apply relative border-2 border-colorSecondary px-3 py-1 lg:px-4 rounded-sm text-[16px] md:text-[14px] lg:text-[16px] xl:text-[20px] uppercase font-medium;
}

.recent-blog-main-card-category-tag-text {
    @apply absolute lg:left-[-6px] lg:top-[-6px] left-[-4px] top-[-4px] bg-colorSecondary px-3 py-1 lg:px-4 rounded-sm uppercase font-medium;
}

.recent-blog-main-card-img {
    @apply rounded-md;
}

.recent-blog-main-card-img img {
    @apply lg:min-h-[150px] xxl:min-h-[200px] group-hover:scale-110 ease-linear duration-300;
}

.recent-blog-main-card-content-date {
    @apply flex items-center text-[17px] font-medium md:text-[15px] lg:text-[16px] xl:text-[18px] mt-0 md:mt-1 mb-2 opacity-60;
}

.recent-blog-main-card-content-ratings {
    @apply flex items-center gap-1 mt-2 text-[20px] md:text-[17px] xl:text-[20px]
}

.recent-blog-main-card-content-ratings span {
    @apply ml-2 text-[17px] md:text-[15px] xl:text-[18px] font-medium
}

.recent-blog-main-card-content {
    @apply md:py-3 py-3;
}

.recent-blog-main-card-content-title {
    @apply text-[22px] md:text-[18px] lg:text-[20px] xl:text-[22px] font-bold leading-tight capitalize font-sans;
}

.recent-blog-secondary-card-content-description {
    @apply md:hidden block;
}

.recent-blog-main-card-link {
    @apply ease-linear duration-100;
}

/* Recent blog secondary card */
.recent-blog-secondary-card-img {
    @apply overflow-hidden;
}

.recent-blog-secondary-card-img-container {
    @apply relative;
}

.recent-blog-secondary-card-category-tag {
    @apply absolute xl:top-4 lg:top-3 md:top-2 top-3   text-colorTextWhite cursor-pointer;
}

.recent-blog-secondary-card-category-tag-border {
    @apply relative border-2 border-colorSecondary px-3 py-1 lg:px-4 rounded-sm text-[16px] md:text-[10px] lg:text-[14px] xl:text-[16px] uppercase font-medium;
}

.recent-blog-secondary-card-category-tag-text {
    @apply absolute lg:left-[-6px] lg:top-[-6px] left-[-4px] top-[-4px] bg-colorSecondary px-3 py-1 lg:px-4 rounded-sm uppercase font-medium font-sans;
}

.recent-blog-secondary-card-img {
    @apply rounded-md;
}

.recent-blog-secondary-card-img img {
    @apply lg:min-h-[150px] xxl:min-h-[200px] group-hover:scale-110 ease-linear duration-300;
}

.recent-blog-secondary-card-content-date {
    @apply flex items-center text-[17px] font-normal md:text-[13px] lg:text-[15px] xl:text-[17px] mt-0 md:mt-2 mb-1 opacity-60;
}

.recent-blog-secondary-card-content-ratings {
    @apply flex items-center gap-1 mb-2 text-[20px] md:text-[17px] xl:text-[20px]; 
}

.recent-blog-secondary-card-content-ratings span {
    @apply ml-2 text-[17px] md:text-[15px] xl:text-[18px] font-medium;
}

.recent-blog-secondary-card-content {
    @apply md:py-1 py-3;
}

.recent-blog-secondary-card-content-title {
    @apply xl:text-[20px] lg:text-[17px] md:text-[15px] text-[22px] font-bold leading-tight capitalize font-sans;
}

.recent-blog-secondary-card-link {
    @apply ease-linear duration-100;
}