
/* Create new blog */
.dashboard-blog-create-wrapper {
    @apply w-full flex flex-col gap-8;
}

.dashboard-blog-create-heading {
    @apply flex items-center gap-3;
}

.dashboard-blog-create-heading-icon {
    @apply text-colorSecondary xl:text-[28px] md:text-[24px] text-[26px];
}

.blog-image-input-container{
    @apply w-full flex flex-col h-auto my-4 justify-start items-center transition ease-in-out delay-150 duration-500;
}

.blog-image-input-error{
    @apply flex w-full;
}

.blog-image-input {
    @apply w-full flex lg:flex-row flex-col flex-1 items-start lg:items-center;
}

.blog-image-input-preview {
    @apply w-full flex flex-1 lg:justify-center justify-start items-center mt-2;
}

.blog-image-input-preview img{
    @apply max-w-[200px] h-[120px] rounded-md nav-shadow;
}

.blog-image-input-preview p {
    @apply font-medium xl:text-[20px] text-[16px] capitalize;
}

.blog-content-input-container {
    @apply relative h-[auto];
} 

.dashboard-main-buttons {
    @apply flex justify-between items-center;
}

.input-error {
    @apply flex items-center text-[14px] xl:text-[16px] mt-3 mb-2 font-light text-colorDanger fade-in;
}

.input-error-text {
    @apply ml-2;
}

.preview-container {
    @apply fixed z-30 top-0 left-0 flex justify-center items-start w-full h-[100vh] bg-colorBgModal fade-in overflow-y-scroll;
}

.preview-close {
    @apply fixed right-[5px] lg:right-[20px] top-[20px];
}
.preview-close > span {
    @apply flex items-center px-2 py-1 rounded-md hover:border-colorTextWhite text-colorWhite uppercase font-semibold text-[12px] xl:text-[14px] cursor-pointer border-2 border-colorBorder duration-200 ease-in-out;
}

.preview-content {
    @apply w-[95%] md:w-[80%] xl:w-[85%] bg-colorTertiary my-[70px] rounded-md;
}

.preview-container::-webkit-scrollbar {
    display: none;
}
