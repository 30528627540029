.table-filter-wrapper {
    @apply w-full absolute -z-10 opacity-0 top-[220%] right-[5%] bg-colorWhite nav-shadow duration-300 rounded-sm pointer-events-none;
}

.table-filter-wrapper-active {
    @apply opacity-100 z-10 top-[150%] pointer-events-auto;
}

.table-filter-options {
    @apply flex flex-col relative;
}

.table-filter-options-item {
    @apply py-3 text-[13px] xl:text-[16px] hover:bg-colorPrimary hover:text-colorTextWhite font-normal cursor-pointer duration-300 rounded-sm;
}

.table-filter-options-item-selected {
    @apply py-3 text-[13px] xl:text-[16px] bg-colorPrimary hover:bg-colorPrimary hover:text-colorTextWhite font-normal cursor-pointer duration-300 rounded-sm;
}