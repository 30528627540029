/* Single blog section */
.single-blog {
    @apply flex flex-col;
}

.single-blog-heading {
    @apply text-center py-2 px-3 md:p-0 text-colorWhite md:text-colorBlack bg-colorPrimary md:bg-opacity-0 rounded-sm;
}

.single-blog-content {
    @apply w-full min-h-[60vh];
}

.single-blog-content-info {
    @apply my-4 md:my-6 text-[15px] sm:text-[17px] lg:text-[18px] xl:text-[22px] ;
}

.single-blog-content-info-text {
    @apply flex items-center justify-center font-normal;
}

.single-blog-content-info-text-date {
    @apply flex items-center;
}

.single-blog-content-info-text span {
    @apply opacity-60 uppercase;
}

.single-blog-content-info-ratings {
    @apply w-full flex justify-center mt-4 md:mt-5 xl:mt-6;
}

.single-blog-ratings-star {
    @apply flex items-center gap-1.5 xl:gap-2 text-[20px] md:text-[22px] xl:text-[26px];
}

.single-blog-ratings-star span {
    @apply ml-2 text-[18px] xl:text-[22px]
}

.single-blog-main-img {
    @apply flex justify-center w-full rounded-lg md:my-8 my-6;
}

.single-blog-main-img-container {
    @apply relative w-full md:w-[80%] lg:w-[75%] xl:w-[70%];
}

.single-blog-category-tag {
    @apply absolute xl:top-5 lg:top-4 md:top-3 top-3 left-[-2]  text-colorTextWhite cursor-pointer;
}

.single-blog-category-tag-border {
    @apply relative border-2 border-colorSecondary  px-3 py-1 md:px-4 md:py-2 lg:px-6 rounded-sm text-[16px] md:text-[14px] lg:text-[16px] xl:text-[20px] uppercase font-medium;
}

.single-blog-category-tag-text {
    @apply absolute lg:left-[-10px] lg:top-[-10px] left-[-5px] top-[-6px]  bg-colorSecondary px-3 py-1 md:px-4 md:py-2 lg:px-6 rounded-sm uppercase font-medium font-sans;
}

.single-blog-main-img img {
    @apply w-full rounded-md;
}

.single-blog-content-description-container {
    @apply flex justify-center;
}

.single-blog-content-description {
    @apply md:w-[95%] w-full;
}

.blog-share-rating {
    @apply py-[20px] xl:py-[25px] md:w-[95%] w-full bg-colorTertiary flex flex-col md:flex-row items-start md:items-center justify-between gap-5 md:gap-0 mx-auto px-5 mt-8 md:mt-12 xl:mt-14;
}

.blog-rating {
    @apply flex items-center gap-6 md:gap-4 xl:gap-5;
}

.blog-rating-title {
    @apply text-[16px] xl:text-[18px] text-colorBlack font-light uppercase underline md:no-underline cursor-pointer hover:underline hover:text-colorPrimary duration-300;
}

.blog-rating-stars {
    @apply flex gap-1 text-[24px] md:text-[22px] xl:text-[24px];
}