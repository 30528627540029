.search-not-found {
    @apply h-[88vh] flex flex-col gap-4 justify-center items-center;
}

.search-not-found-heading {
    @apply text-[50px] font-semibold capitalize font-sans;
}

.search-not-found-heading span {
    @apply text-colorAccent;
}

.search-not-found-text {
    @apply text-[22px] font-light;
}