/* About Me */
.about-me {
    @apply relative w-full h-[100vh] flex lg:flex-row flex-col-reverse justify-center items-center  xl:px-[100px] lg:px-[80px] md:px-[30px] px-[20px];
}

.about-me-info {
    @apply flex flex-1 w-full flex-col lg:items-start items-center lg:justify-start justify-center md:mt-0 mt-5;
}

.about-me-info-heading1 {
    @apply xl:text-[30px] lg:text-[26px] md:text-[22px] text-[20px] capitalize font-normal md:mb-2 mb-[-8px] fade-in-bottom;
}

.about-me-info-heading2 {
    @apply flex gap-4 xl:text-[48px] lg:text-[40px] md:text-[36px] text-[24px] mt-3 capitalize font-bold;
}

.about-me-info-heading2-dynamic-text {
    @apply relative;
}

.about-me-info-heading2-dynamic-text::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #26b9c2;
    animation: blinkCursor 0.8s steps(3) infinite;
}

.about-me-info-heading2-dynamic-text p {
    @apply text-colorPrimary tracking-[5px] capitalize overflow-hidden typing-animation;
}

.about-me-info-description {
    @apply text-justify;
}

.about-me-social-links-icons {
    @apply absolute z-20 md:w-auto w-full xl:left-[100px] lg:left-[80px] md:left-[30px] left-0 bottom-0 flex md:justify-start justify-evenly gap-6 bg-colorTertiary px-6 xl:pt-3 pt-2.5 pb-2;
}

.about-me-social-links-icon {
    @apply text-colorBgHover hover:text-colorSecondary hover:scale-125 xl:text-[28px] lg:text-[25px] md:text-[22px] text-[30px] ease-linear duration-200;
}

.about-me-img {
    @apply relative flex md:flex-1 flex-none justify-center lg:mt-0 mt-[100px];
}

.about-me-img-profile-container {
    @apply flex items-center xl:w-[35%] lg:w-[30%] md:w-[34%] w-[55%] z-20;
}

.about-me-img-profile {
    @apply w-full motion card-shadow rounded-md;
}

.about-me-img-shape {
    @apply xl:w-[100%] lg:w-[95%] md:w-[75%] w-[100%] absolute xl:top-[-70%] lg:top-[-80%] top-0 lg:right-[-10%] mr-auto z-10 motion-reverse;
}

.home-link {
    @apply fixed lg:top-[30px] top-[20px] xl:left-[50px] lg:left-[40px] left-[20px] xl:text-[18px] text-[16px];
}

.home-link a {
    @apply flex items-center uppercase font-normal hover:text-colorAccent ease-linear duration-100;
}