/* Dashboard sidebar css */
.dashboard-sidebar {
    @apply z-30 md:relative absolute md:left-0 left-[-100%] md:w-auto w-[100%] h-[100%] bg-colorTertiary ease-linear duration-300;
}

.dashboard-sidebar-sm-active {
    @apply left-0;
}

.dashboard-sidebar-brand {
    @apply py-5 flex flex-col xl:gap-3 gap-2 justify-center items-center bg-gradient-to-t from-colorPrimary to-colorSecondary;
}

.dashboard-sidebar-brand-title {
    @apply text-colorWhite capitalize font-light xl:text-[16px] text-[14px];
}

.dashboard-sidebar-brand a {
    @apply flex items-center gap-2 xl:text-[24px] lg:text-[22px] md:text-[18px] text-[20px] uppercase text-colorTextWhite font-quickSand font-bold;
}

.dashboard-sidebar-list-container {
    @apply w-full flex flex-col gap-1 xl:gap-1.5 py-4 items-center overflow-y-auto custom-scrollbar;
}

.dashboard-sidebar-list {
    @apply lg:w-[90%] md:w-[95%] w-[85%] flex justify-center text-colorBlack hover:bg-colorTextWhite hover:text-colorSecondary rounded-md ease-linear duration-200;
}

.dashboard-sidebar-list-active {
    @apply lg:w-[90%] md:w-[95%] w-[85%] flex justify-center bg-colorTextWhite text-colorSecondary rounded-md ease-linear duration-200;
}

.dashboard-sidebar-list-item {
    @apply w-full flex items-center px-5 xl:gap-3.5 lg:gap-3 md:gap-2 gap-3 xl:py-5 py-4 xl:text-[17px] md:text-[15px] text-[16px] capitalize font-medium;
}

.dashboard-sidebar-list-item-icon {
    @apply xl:text-[20px] lg:text-[18px] md:text-[16px] text-[20px] text-colorSecondary;
}

.dashboard-sidebar-toggle {
    @apply md:hidden z-10 fixed p-1 top-5 left-4 flex items-center gap-2 capitalize text-[26px] rounded-sm cursor-pointer;
}