/* Hero Section */
.hero {
    @apply w-[100vw] h-[88vh] bg-colorTertiary flex items-center justify-center mb-10 bg-cover bg-center bg-no-repeat;
}

.hero-content {
    @apply flex px-6 xl:px-8 py-4 xl:py-5 bg-colorTextWhite xl:max-w-[55%] md:max-w-[52%] sm:max-w-[85%] max-w-[100%] img-shadow;
}

.hero-heading {
    @apply flex flex-col w-auto justify-center;
}

.hero-heading-title {
    @apply flex items-center gap-3 text-[26px] md:text-[24px] lg:text-[30px] xl:text-[40px] font-sans tracking-wide font-extrabold uppercase;
}

.hero-heading-description {
    @apply text-left;
}

.hero-btn-container {
    @apply md:mt-1 mt-3;
}

.hero-btn {
    @apply w-auto inline-flex items-center px-3 py-2.5 xl:py-3 bg-colorPrimary hover:bg-colorSecondary text-colorTextWhite xl:text-[18px] md:text-[15px] text-[16px] capitalize rounded-lg ease-linear duration-100;
}

.hero-btn-target {
    @apply opacity-0 absolute left-0 sm:bottom-[50px] bottom-[100px];
}

.hero-heading-img {
    @apply sm:w-[7%] xl:w-[7.5%] w-[13%] ;
}
