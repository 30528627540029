/* Tooltip css */
.tooltip-wrapper {
    @apply opacity-0 group-hover:opacity-100 pointer-events-none;
}

.tooltip {
    @apply z-20 px-[8px] py-[1px] absolute bottom-[135%] left-[-100%] bg-colorPrimary text-colorTextWhite text-[14px] text-center rounded;
}

.tooltip-arrow {
   position: absolute;
   left: 0;
   bottom: 100%;
   border-top: 10px solid #3793a6;
   border-left: 10px solid transparent;
   border-right: 10px solid transparent;
   z-index: 10;
}