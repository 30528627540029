@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Outfit:wght@100..900&display=swap');

/* Importing all css files */
@import url('./components/dashboard/index.css');
@import url('./components/index.css');
@import url('./ui/pages/index.css');
@import url('./ui/dashboard/index.css');

body {
	background-color: #f5f5f5;
	padding: 0;
	margin: 0;
	overflow: scroll;
	box-sizing: border-box;
	color: #404d56;
	font-family: 'Outfit', sans-serif;
	font-weight: normal;
	scroll-behavior: smooth;
	letter-spacing: 0.05em;
	-webkit-user-select: none; /* Safari */
  	-ms-user-select: none; /* IE 10 and IE 11 */
  	user-select: none; /* Standard syntax */
}

body::-webkit-scrollbar {
	display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 1000px white inset !important;
	box-shadow: 0 0 0 1000px white inset !important;
	font-size: 16px !important;
}

p{
	white-space: pre-wrap;
}

img {
	pointer-events: none;
}


li {
	list-style-type: none;
}

button:disabled {
	opacity: 0.5;
}
/* Media Query */
@media screen and (max-width: 1440px) {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 1000px white inset !important;
		box-shadow: 0 0 0 1000px white inset !important;
		font-size: 14px !important;
	}
}

/* Custom file input */
.form-input-file::-webkit-file-upload-button {
	visibility: hidden;
	width: 0;
}

.form-input-file:focus {
	outline: none !important;
}

.form-input-file {
	background-color: #ffffff !important;
	transition: 300s ease-in-out;
	cursor: pointer;
}

.form-input-file::before {
	content: 'Select an Image';
	display: inline-block;
	background: #26b9c2;
	border-radius: 3px;
	padding: 10px 12px !important;
	margin:0;
	outline: none;
	white-space: nowrap;
	font-weight: normal;
	font-size: 1rem;
	color: #f5f5f5;
	text-transform: capitalize;
	transition: 300s ease-in-out;
}

/* Image placeholder */
.img-placeholder {
	position: relative;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	border-radius: 6px;
}

.img-placeholder::before {
	content: "";
	position: absolute;
	inset: 0;
	opacity: 1;
	animation: pulse 2.5s infinite;
	background: rgba(255, 255, 255, 0.8);
}

.img-original{
	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: cover;
	border-radius: 6px;
	opacity: 0;
	transition: 300ms ease-in-out;
}

.img-placeholder.loaded::before {
	content: none;
}

@keyframes pulse {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.1;
	}
	100% {
		opacity: 0;
	}
}

.img-placeholder.loaded > .img-original{
	opacity: 1;
}

.img-placeholder.loaded::before {
	content: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

	/* custom-scrollbar */
	/* width */
	.custom-scrollbar::-webkit-scrollbar {
		width: 3px;
	}

	/* Track */
	.custom-scrollbar::-webkit-scrollbar-track {
		background: #f1f1f1; 
		box-shadow: inset 0 0 5px #dbdada; 
	}

	/* Handle */
	.custom-scrollbar::-webkit-scrollbar-thumb {
		background: rgba(256, 256, 256, 0.8);
		border-radius: 50px; 
	}

	/* Handle on hover */
	.custom-scrollbar::-webkit-scrollbar-thumb:hover {
		background: rgba(256, 256, 256);
	}

	.nav-shadow {
	-webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 60px;
	-moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 60px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 60px;
	}

	.card-shadow {
	-webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
	-moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
	}

	.form-shadow {
	-webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
	-moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
	}

	.img-shadow {
	-webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	-moz-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
	}

	.bg-transparent {
		background-color: transparent;
	}

	/* Button section */
	.btn-primary {
		@apply inline-block px-3 py-3 cursor-pointer xl:px-4 xl:py-3 xxl:px-6 xxl:py-4 mt-1 md:mt-2 xl:mt-3  xxl:mt-4 rounded-md bg-gradient-to-t from-colorPrimary to-colorSecondary hover:from-colorSecondary hover:to-colorSecondary text-sm lg:text-base xl:text-lg text-colorTextWhite capitalize font-sans ease-linear duration-300;
	}

	.btn-submit {
		@apply w-full px-5 py-3 md:px-5 md:py-2.5 lg:px-0 cursor-pointer xl:px-6 xl:py-3 xxl:px-8 xxl:py-4 rounded-md bg-gradient-to-r from-colorPrimary to-colorSecondary hover:from-colorSecondary hover:to-colorSecondary hover:bg-opacity-75 xl:text-[16px] md:text-[15px] text-[17px] font-medium tracking-[0.5px]  text-colorTextWhite uppercase ease-linear duration-300;
	}

	.avatar {
		@apply w-[30px] h-[30px] rounded-full mr-2 card-shadow;
	}

	.page-scroll-progress {
		@apply absolute bg-colorPrimary w-full h-1 bottom-0 left-0 ease-linear duration-100;
	}
	
	/* Form section */

	form {
		@apply w-full md:w-[90%];
	}
	
	.custom-checkbox {
		@apply bg-colorWhite border-[0.5px] border-colorBgHover cursor-pointer;
	}

	.custom-checkbox-active {
		@apply bg-colorPrimary border-[0.5px] border-colorPrimary;
	}

	.check-icon {
		@apply text-[12px] xl:text-[14px] text-colorTextWhite cursor-pointer;
	}

	/* Scroll top section */
	.scroll-top-container {
		@apply fixed bottom-5 right-5 ease-linear duration-500;
	}

	.scroll-top-container-hidden {
		@apply fixed bottom-[-80px] right-5 ease-linear duration-500;
	}

	.scroll-top {
		@apply xl:w-[65px] xl:h-[65px] lg:w-[55px] lg:h-[55px] w-[60px] h-[60px] flex justify-center items-center p-6 bg-gradient-to-t from-colorPrimary to-colorSecondary hover:from-colorSecondary hover:to-colorSecondary rounded-full cursor-pointer shadow-lg duration-200;
	}

	.scroll-top-icon {
		@apply xl:text-[35px] lg:text-[30px] text-[30px] text-colorTextWhite;
	}

	.scroll-top-icon-hidden {
		@apply hidden;
	}

	.scroll-top:hover > .scroll-top-icon {
		@apply rocket-animation;
	}
	/* End of scroll top */

	
	/* Dashboard Section */
	.dashboard-container {
		display: grid;
		height: 100vh;
		grid-template-rows: 0fr 1fr;
		grid-template-columns: 280px 1fr 1fr 1fr;
		grid-template-areas:
			'sidebar header header header'
			'sidebar main main main';

	}

	.dashboard-main::-webkit-scrollbar {
		display: none;
	}

	.dashboard-header {
		grid-area: header;
	}

	.dashboard-sidebar {
		grid-area: sidebar;
	}

	.dashboard-main {
		grid-area: main;
		overflow-y: auto;
		position: relative;
	}

	/* Media query for dashboard */

	@media screen and (max-width: 1440px) {
		.dashboard-container {
			grid-template-columns: 250px 1fr 1fr 1fr;
		}
		
		/* Custom select */
		.custom-select-selected {
			padding-left: 40px !important;
			padding-right: 15px !important;
		}
	}

	@media screen and (max-width: 1024px) {
		.dashboard-container {
			grid-template-columns: 210px 1fr 1fr 1fr;
		}
		
		/* Custom select */
		.custom-select-selected {
			padding-left: 30px !important;
			padding-right: 10px !important;
		}

	}

	@media screen and (max-width: 768px) {
		.dashboard-container {
			grid-template-columns: 1fr;
			grid-template-areas:
				'header '
				'main ';
		}

		.dashboard-sidebar {
			width: 75%;
		}

	}

	.dashboard-main {
		@apply h-[100%] flex flex-col justify-start items-center xl:text-[18px] text-[16px] font-light xl:px-14 md:px-10 xl:py-8 md:py-8 p-5;
	}

	.input-error {
		@apply flex items-center text-[14px] xl:text-[16px] mt-3 mb-2 font-light text-colorDanger fade-in;
	}

	.input-error-text {
		@apply ml-2;
	}

	/* Custom Animations */
	.circular-animation1 {
		-webkit-animation: circle1 6s linear infinite;
		animation: circle1 6s linear infinite;
	}

	/* Custom Animations */
	.circular-animation1 {
		-webkit-animation: circle1 6s linear infinite;
		animation: circle1 6s linear infinite;
	}

	/* Custom Animations */
	.circular-animation1 {
		-webkit-animation: circle1 6s linear infinite;
		animation: circle1 6s linear infinite;
	}

	/* Circular animation */

	.circular-animation2 {
		-webkit-animation: circle2 6s linear infinite;
		animation: circle2 6s linear infinite;
	}

	/* Media Query for circular aniamtion */
	@media screen and (max-width: 1700px) {
		@keyframes circle1 {
			0% {
				transform: rotate(0deg) translate(-140px) rotate(0deg);
			}
			100% {
				transform: rotate(360deg) translate(-140px) rotate(-360deg);
			}
		}

		/* Circular animation */
		@keyframes circle2 {
			0% {
				transform: rotate(0deg) translate(140px) rotate(0deg);
			}
			100% {
				transform: rotate(360deg) translate(140px) rotate(-360deg);
			}
		}
	}

	@media screen and (max-width: 1440px) {
		@keyframes circle1 {
			0% {
				transform: rotate(0deg) translate(-115px) rotate(0deg);
			}
			100% {
				transform: rotate(360deg) translate(-115px) rotate(-360deg);
			}
		}

		/* Circular animation */
		@keyframes circle2 {
			0% {
				transform: rotate(0deg) translate(115px) rotate(0deg);
			}
			100% {
				transform: rotate(360deg) translate(115px) rotate(-360deg);
			}
		}
	}

	@media screen and (max-width: 1024px) {
		@keyframes circle1 {
			0% {
				transform: rotate(0deg) translate(-130px) rotate(0deg);
			}
			100% {
				transform: rotate(360deg) translate(-130px) rotate(-360deg);
			}
		}

		/* Circular animation */
		@keyframes circle2 {
			0% {
				transform: rotate(0deg) translate(130px) rotate(0deg);
			}
			100% {
				transform: rotate(360deg) translate(130px) rotate(-360deg);
			}
		}

	}

	@media screen and (max-width: 768px) {
		@keyframes circle1 {
			0% {
				transform: rotate(0deg) translate(-75px) rotate(0deg);
			}
			100% {
				transform: rotate(360deg) translate(-75px) rotate(-360deg);
			}
		}

		/* Circular animation */
		@keyframes circle2 {
			0% {
				transform: rotate(0deg) translate(75px) rotate(0deg);
			}
			100% {
				transform: rotate(360deg) translate(75px) rotate(-360deg);
			}
		}
	}

	.typing-animation {
		-webkit-animation: typing 6s steps(13) infinite;
		animation: typing 6s steps(13) infinite;
	}

	/* Media Query for typing aniamtion */
	@media screen and (min-width: 1700px) {
		@-webkit-keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 413.88px;
			}
		}

		@keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 413.88px;
			}
		}
	}

	@media screen and (max-width: 1700px) {
		@-webkit-keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 413.88px;
			}
		}

		@keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 413.88px;
			}
		}
	}

	@media screen and (max-width: 1440px) {
		@-webkit-keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 356.56px;
			}
		}

		@keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 356.56px;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		@-webkit-keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 327.91px;
			}
		}

		@keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 327.91px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		@-webkit-keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 241.94px;
			}
		}

		@keyframes typing {
			0%,
			90%,
			100% {
				width: 0;
			}
			30%,
			60% {
				width: 241.94px;
			}
		}
	}

	@-webkit-keyframes blinkCursor {
		0%,
		75% {
			opacity: 1;
		}
		76%,
		100% {
			opacity: 0;
		}
	}

	@keyframes blinkCursor {
		0%,
		75% {
			opacity: 1;
		}
		76%,
		100% {
			opacity: 0;
		}
	}

	.motion {
		-webkit-animation: motion 4s linear infinite;
		animation: motion 4s linear infinite;
	}

	@-webkit-keyframes motion {
		50% {
			transform: translateX(20px) translateY(10px);
		}
	}

	@keyframes motion {
		50% {
			transform: translateX(20px) translateY(10px);
		}
	}

	.motion-reverse {
		-webkit-animation: motion-reverse 4s linear infinite;
		animation: motion-reverse 4s linear infinite;
	}

	@-webkit-keyframes motion-reverse {
		50% {
			transform: translateX(-20px) translateY(-10px);
		}
	}

	@keyframes motion-reverse {
		50% {
			transform: translateX(-20px) translateY(-10px);
		}
	}

	.rocket-animation {
		-webkit-animation: rocket-motion 1s linear infinite;
		animation: rocket-motion 1s linear infinite;
	}

	@-webkit-keyframes rocket-motion {
		50% {
			-webkit-transform: translateY(-3px);
			transform: translateY(-3px);
		}
	}

	@keyframes rocket-motion {
		50% {
			-webkit-transform: translateY(-3px);
			transform: translateY(-3px);
		}
	}

	/* Animista Anmations */
	.rotate {
		-webkit-animation: rotate 1.6s ease-in-out 1s infinite both;
		animation: rotate 1.6s ease-in-out 1s infinite both;
	}

	@-webkit-keyframes rotate {
		0% {
			-webkit-transform: rotate(0);
			transform: rotate(0);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes rotate {
		0% {
			-webkit-transform: rotate(0);
			transform: rotate(0);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	.fade-in-bottom {
		-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
			both;
		animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	}

	@-webkit-keyframes fade-in-bottom {
		0% {
			-webkit-transform: translateY(50px);
			transform: translateY(50px);
			opacity: 0;
		}
		100% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
			opacity: 1;
		}
	}
	@keyframes fade-in-bottom {
		0% {
			-webkit-transform: translateY(50px);
			transform: translateY(50px);
			opacity: 0;
		}
		100% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
			opacity: 1;
		}
	}

	.fade-in {
		-webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
		animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	}

	@-webkit-keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.scale-in-center {
		-webkit-animation: scale-in-center 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
			both;
		animation: scale-in-center 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	}

	@-webkit-keyframes scale-in-center {
		0% {
			-webkit-transform: scale(0);
			transform: scale(0);
			opacity: 1;
		}
		100% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}
	@keyframes scale-in-center {
		0% {
			-webkit-transform: scale(0);
			transform: scale(0);
			opacity: 1;
		}
		100% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}

	.fade-out {
		-webkit-animation: fade-out 1s ease-out both;
		animation: fade-out 1s ease-out both;
	}

	@-webkit-keyframes fade-out {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
	@keyframes fade-out {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	.text-focus-in {
		-webkit-animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
			both;
		animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	}

	@-webkit-keyframes text-focus-in {
		0% {
			-webkit-filter: blur(12px);
			filter: blur(12px);
			opacity: 0;
		}
		100% {
			-webkit-filter: blur(0px);
			filter: blur(0px);
			opacity: 1;
		}
	}
	@keyframes text-focus-in {
		0% {
			-webkit-filter: blur(12px);
			filter: blur(12px);
			opacity: 0;
		}
		100% {
			-webkit-filter: blur(0px);
			filter: blur(0px);
			opacity: 1;
		}
	}

	.pulsate-bck {
		-webkit-animation: pulsate-bck 0.6s ease-in-out 2s infinite both;
		animation: pulsate-bck 0.6s ease-in-out 2s infinite both;
	}

	@-webkit-keyframes pulsate-bck {
		0% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		50% {
			-webkit-transform: scale(0.9);
			transform: scale(0.9);
		}
		100% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
	@keyframes pulsate-bck {
		0% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		50% {
			-webkit-transform: scale(0.9);
			transform: scale(0.9);
		}
		100% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
}
