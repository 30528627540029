/* Blog comments css */
.blog-comment {
    @apply w-full md:w-[95%] flex flex-col mx-auto mt-[45px] md:mt-[60px] xl:mt-[80px];
}

.blog-comment-heading {
    @apply w-full flex flex-col md:flex-row gap-4 md:gap-0 justify-between text-[17px] md:text-[16px] xl:text-[18px] pb-3 border-b-[0.5px] border-b-colorDivider;
}

.blog-comment-heading-left {
    @apply flex gap-3;
}

.blog-comment-title-1 {
    @apply bg-colorPrimary text-colorTextWhite py-1 px-3 rounded-sm capitalize;
}

.blog-comment-title-2 {
    @apply border-[0.5px] border-colorDivider hover:bg-colorTertiary hover:border-colorTertiary py-1 px-3 rounded-sm capitalize duration-300;
}

.blog-comment-count {
    @apply bg-colorTertiary py-1 px-3 capitalize;
}

.blog-comment-count span {
    @apply font-bold text-colorPrimary mr-1;
}

.blog-comment-body {
    @apply flex flex-col py-4 md:py-8;
}

.blog-comment-list {
    @apply w-full md:w-[85%] flex flex-col gap-1 xl:gap-2 mx-auto;
}

.blog-comment-empty {
    @apply w-full md:w-[80%] min-h-[100px] xl:min-h-[150px] flex flex-col justify-center items-center gap-4 mx-auto;
}

.blog-comment-empty-title-1 {
    @apply capitalize text-[18px] md:text-[20px] xl:text-[24px] font-medium font-sans;
}

.blog-comment-empty-title-2 {
    @apply flex items-center gap-2 capitalize text-[16px] xl:text-[20px] font-light;
}

.blog-comment-list-title {
    @apply w-auto px-2 py-2 xl:py-3 mb-0 md:mb-1 bg-colorTertiary capitalize;
}

.blog-comment-list-footer {
    @apply w-full flex justify-end mt-3;
}

.blog-comment-list-footer button {
    @apply bg-colorPrimary hover:bg-colorSecondary text-colorTextWhite text-[14px] xl:text-[16px] py-2 px-3 capitalize rounded-sm duration-300;
}

.blog-comment-post {
    @apply flex flex-col w-full md:w-[60%] gap-5 mx-auto;	
}

.blog-comment-post-title {
    @apply flex flex-col gap-1 text-[18px] md:text-[16px] xl:text-[20px] font-medium font-sans capitalize;
}

.blog-comment-post-title span {
    @apply w-[20%] h-[2px] bg-colorPrimary;
}

.blog-comment-post form  {
    @apply w-full;
}

.blog-comment-post form textarea {
    @apply relative px-6;
}

.blog-comment-btn {
    @apply flex gap-3 absolute bottom-5 right-4 md:right-6 xl:right-8; 
}

.blog-comment-btn-submit {
    @apply bg-colorPrimary hover:bg-colorSecondary px-2 py-1 text-[16px] xl:text-[16px] text-colorTextWhite capitalize rounded-sm duration-300;
}