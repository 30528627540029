
.preview-content-image {
    @apply flex w-[20%] h-[20%] justify-start;
}

.preview-content-image img {
    @apply w-[90%] rounded-md z-20 border-[5px] border-colorTextWhite;
}

.preview-content-info {
    @apply flex flex-col w-[80%];
}

.preview-content-info-heading {
    @apply bg-colorPrimary text-colorTextWhite capitalize px-6 py-2 rounded-t-md;
}

.preview-content-info-details {
    @apply flex flex-col gap-2 bg-colorTextWhite p-6 img-shadow rounded-b-md;
}

.preview-content-info-detail {
    @apply flex bg-colorTertiary p-3;
}

.preview-content-info-detail-title {
    @apply  flex w-[30%] capitalize font-medium;
}

.preview-content-info-detail-description {
    @apply flex w-[70%] capitalize;
}