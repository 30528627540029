.blog-share {
    @apply flex items-center gap-6 md:gap-4 xl:gap-5;
}

.blog-share-title{
    @apply text-[16px] xl:text-[18px] text-colorBlack font-light uppercase underline md:no-underline;
}

.blog-share-icon-list {
    @apply flex gap-2 xl:gap-3;
}

.blog-share-icon {
    @apply text-[26px] md:text-[24px] xl:text-[28px] text-colorBgHover hover:text-colorPrimary hover:scale-110 cursor-pointer duration-200;
}