
/* Blog Card section */
.card {
    @apply flex flex-col;
}

.card-thumbnail {
    @apply flex;
}

.card-img {
    @apply overflow-hidden;
}

.card-img-container {
    @apply relative;
}

.card-category-tag {
    @apply absolute xl:top-4 lg:top-3 md:top-2 top-3  text-colorTextWhite cursor-pointer;
}

.card-category-tag-border {
    @apply relative border-2 border-colorSecondary px-3 py-1 lg:px-4 rounded-sm text-[16px] md:text-[14px] lg:text-[16px] xl:text-[18px] uppercase font-medium;
}

.card-category-tag-text {
    @apply absolute lg:left-[-6px] lg:top-[-6px] left-[-4px] top-[-4px] bg-colorSecondary px-3 py-1 lg:px-4 rounded-sm uppercase font-medium font-sans;
}

.card-img {
    @apply rounded-md;
}

.card-img img {
    @apply lg:min-h-[180px] xxl:min-h-[200px] group-hover:scale-110 ease-linear duration-300;
}

.card-content-date {
    @apply flex items-center text-[17px] md:text-[16px] font-normal xl:text-[18px] mt-0 md:mt-1 mb-3 opacity-60;
}

.card-content-ratings {
    @apply flex items-center mt-2 gap-1 text-[20px] md:text-[17px] xl:text-[20px]; 
}

.card-content-ratings span {
    @apply ml-2 text-[17px] md:text-[15px] xl:text-[18px] font-medium;
}

.card-content {
    @apply py-3;
}

.card-link {
    @apply ease-linear duration-100;
}