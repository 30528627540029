.table-row-limit-wrapper {
    @apply relative text-[14px] md:text-[16px]
}

.table-row-limit-btn {
    @apply flex items-center gap-4 capitalize bg-colorTableHead px-2 py-2.5 md:py-2.5 rounded-sm border-[1px] border-colorDivider cursor-pointer;
}

.table-row-limit-dropdown {
    @apply z-30 absolute opacity-100 w-full left-0 top-[100%] bg-colorWhite rounded-bl-sm rounded-br-sm nav-shadow duration-300; 
}

.table-row-limit-dropdown-hidden {
    @apply left-0 top-[140%] opacity-0 pointer-events-none;
}

.table-row-limit-dropdown-list{
    @apply w-full text-center;
}

.table-row-limit-dropdown-list-item {
    @apply w-full hover:bg-colorPrimary hover:text-colorTextWhite py-2.5 md:py-2.5 cursor-pointer duration-300;
}