/* Dashboard table css */
.table-wrapper {
    @apply max-h-[65vh] overflow-y-auto overflow-x-hidden table-scrollbar;
}

table {
    @apply w-full bg-colorWhite text-[14px] md:text-[16px];
}

thead {
    @apply z-10 sticky top-0 left-0;
}

th {
    @apply px-[15px] py-[18px] text-center bg-colorTableHead capitalize font-medium;
}

tbody tr {
    @apply font-light hover:bg-colorTableHover duration-200;
}

td {
    @apply px-[15px] py-[20px] text-center capitalize border-b-[1.5px] border-b-colorBorder;
}

.table-title-wrapper {
    @apply flex justify-center items-center gap-6 relative cursor-pointer;
}

.table-title-wrapper:hover > .table-filter-icon {
    @apply text-colorPrimary;
}

.table-filter-icon {
    @apply duration-300;
}

.table-filter-icon-active {
    @apply text-colorPrimary;
}

.table-btns {
    @apply flex justify-center items-center gap-4 text-colorBgHover cursor-pointer duration-300;
}

.table-btn-view {
    @apply relative hover:text-colorPrimary;
}

.table-btn-edit {
    @apply relative hover:text-colorPrimary;
}

.table-btn-delete {
    @apply relative hover:text-colorDanger;
}


/* table-scrollbar */
/* width */
.table-scrollbar::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.table-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; 
    box-shadow: inset 0 0 5px rgb(219, 218, 218, 0.2); 
}

/* Handle */
.table-scrollbar::-webkit-scrollbar-thumb {
    background: #26b9c2;
    border-radius: 100px; 
}

/* Handle on hover */
.table-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(256, 256, 256);
}