/* Thumbnail section */

.thumbnail {
    @apply flex gap-4 capitalize card-shadow;
}

.thumbnail-img {
    @apply flex xl:w-[22%] lg:w-[25%] md:w-[22%] sm:w-[18%] w-[30%] xl:h-[70px] md:h-[60px] h-[70px] overflow-hidden;
}

.thumbnail-img img {
    @apply group-hover:scale-110 ease-linear duration-300;
}

.thumbnail-text {
    @apply flex flex-col lg:gap-2 md:gap-1 gap-2 py-1 xl:w-[70%] md:w-[68%] sm:w-[80%] w-[70%] leading-5;
}

.thumbnail-text-title {
    @apply xl:text-[18px] md:text-[15px] text-[16px] font-bold group-hover:text-colorPrimary ease-linear duration-200 font-sans;
}

.thumbnail-text-date {
    @apply xl:text-[16px] md:text-[14px] text-[15px] font-medium opacity-60;
}