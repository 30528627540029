/* Dashboard Main */
.dashboard-cookie-policy-wrapper {
    @apply w-full flex flex-col gap-10;
}

.dashboard-cookie-policy-heading {
    @apply flex items-center gap-3;
}

.dashboard-cookie-policy-heading-icon {
    @apply text-colorSecondary xl:text-[28px] md:text-[24px] text-[26px];
}

.dashboard-cookie-policy-content {
    @apply flex flex-col gap-6
}
