
	/* Footer section */

	.footer-container {
		@apply relative overflow-hidden w-[100%] bg-colorBlack h-auto text-colorTextWhite pt-8;
	}

	.footer-content {
		@apply flex md:flex-row flex-col mb-10 md:gap-0 gap-10;
	}

	.footer-content-company-info {
		@apply flex flex-col md:items-start items-center gap-2 md:w-[30%] w-[100%];
	}

	.footer-content-company-info-logo {
		@apply text-colorSecondary;
	}

	.footer-content-company-info-description {
		@apply font-light xl:text-[16px] lg:text-[15px] text-[14px] md:max-w-[90%] max-w-[100%] md:text-left text-center;
	}

	.footer-content-social-links {
		@apply flex md:flex-col flex-col md:items-start items-center gap-2 mt-6;
	}

	.footer-content-social-links-title {
		@apply capitalize xl:text-[16px] lg:text-[15px] md:text-[14px] text-[16px] font-semibold;
	}

	.footer-content-social-links-icons {
		@apply flex gap-2;
	}

	.footer-content-social-links-icon {
		@apply text-colorBorder hover:text-colorSecondary xl:text-[28px] lg:text-[25px] md:text-[22px] text-[28px] ease-linear duration-200;
	}

	.footer-content-links-section {
		@apply flex md:flex-row flex-col md:justify-end justify-center md:w-[70%] w-[100%] lg:gap-[80px] md:gap-[40px] gap-[35px];
	}

	.footer-content-links {
		@apply flex flex-col lg:gap-4 md:gap-3 gap-2.5 md:items-start items-center;
	}

	.footer-content-links-heading {
		@apply flex flex-col uppercase xl:text-[16px] lg:text-[14px] md:text-[13px] text-[16px] font-medium;
	}

	.footer-content-links-heading-underline {
		@apply md:w-[70%] w-[100%] h-[2px] mt-1 rounded-full bg-colorSecondary;
	}

	.footer-content-links-lists {
		@apply flex flex-col xl:gap-3 lg:gap-2.5 md:gap-2 gap-2 capitalize font-normal xl:text-[17px] md:text-[15px] text-[16px] md:items-start items-center;
	}

	.footer-content-links-lists a {
		@apply hover:text-colorSecondary ease-linear duration-100;
	}

	.footer-copyright {
		@apply flex md:justify-end justify-center text-center xl:text-[15px] lg:text-[14px] md:text-[13px] text-[12px] font-extralight py-2;
	}