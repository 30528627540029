/* Dashboard privacy policy */
.dashboard-privacy-policy-wrapper {
    @apply w-full flex flex-col gap-10;
}

.dashboard-privacy-policy-heading {
    @apply flex items-center gap-3;
}

.dashboard-privacy-policy-heading-icon {
    @apply text-colorSecondary xl:text-[28px] md:text-[24px] text-[26px];
}

.dashboard-privacy-policy-content {
    @apply flex flex-col gap-6
}