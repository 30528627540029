/* Dashboard home */
.dashboard-home-wrapper {
    @apply w-full flex flex-col gap-10;
}

.dashboard-home-heading {
    @apply flex items-center gap-3;
}

.dashboard-home-heading-icon {
    @apply text-colorSecondary xl:text-[28px] md:text-[24px] text-[26px];
}

.dashboard-main-content {
    @apply flex flex-col gap-6
}