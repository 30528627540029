/* Dashboard header css */
.dashboard-header {
    @apply flex items-center justify-between lg:px-6 px-3 py-4 lg:py-2 xl:py-5 bg-colorTertiary;
}

.dashboard-header-left {
    @apply md:block hidden text-colorPrimary font-bold text-[18px] xl:text-[24px];
}

.dashboard-header-left-sm {
    @apply md:hidden flex items-end gap-2 text-[18px] ml-[50px] text-colorPrimary uppercase font-bold;
}

.dashboard-header-right {
    @apply flex  xl:text-[22px] text-[18px] lg:gap-6 md:gap-4 gap-5 items-center;
}

.dashboard-header-right-message {
    @apply relative;
}

.dashboard-header-right-notification {
    @apply relative;
}

.dashboard-header-right-badge {
    @apply absolute xl:top-[-65%] xl:right-[-65%] lg:top-[-70%] lg:right-[-70%] top-[-65%] right-[-65%] flex items-center justify-center xl:min-w-[20px] xl:min-h-[20px] lg:min-w-[18px] lg:min-h-[18px] min-w-[14px] min-h-[14px] aspect-square text-colorTextWhite rounded-[100%];
}

.dashboard-header-right-icon {
    @apply xl:text-[20px] lg:text-[18px] md:text-[18px] text-[19px] cursor-pointer text-colorBlack hover:text-colorSecondary ease-linear duration-200;
}

.dashboard-header-right-user {
    @apply relative flex items-center lg:gap-1.5 md:gap-2 gap-0.5 cursor-pointer md:px-3 px-0 md:ml-0 ml-2 py-2;
}

.dashboard-header-right-user-name {
    @apply md:block hidden capitalize text-[14px] xl:text-[18px] text-colorPrimary font-semibold;
}

.dashboard-header-right-user-dropdown-icon {
    @apply ease-linear duration-200;
}

.dashboard-header-right-user-dropdown-icon-active {
    @apply -rotate-90 ease-linear duration-200;
}

.dashboard-header-right-user-dropdown-list {
    @apply opacity-0 z-20 xl:min-w-[220px] lg:min-w-[200px] min-w-[180px] absolute right-0 top-[100%] md:px-4 px-3 xl:py-2 md:py-2 py-1.5 bg-colorWhite rounded-md ease-linear duration-300 card-shadow pointer-events-none;
}

.dashboard-header-right-user-dropdown-list-active {
    @apply opacity-100 pointer-events-auto;
}

.dashboard-header-right-user-dropdown-list-item {
    @apply flex items-center gap-2 xl:py-2 lg:py-1.5 py-1.5 xl:text-[15px] text-[14px]   text-colorBlack hover:text-colorSecondary capitalize ease-linear duration-300;
}

.dashboard-header-right-user-dropdown-list-item-icon {
    @apply xl:text-[17px] text-[16px];
}