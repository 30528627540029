.pagination-wrapper {
    @apply w-full flex items-center justify-end gap-2 bg-colorTableHead py-3 pr-5 text-[16px] font-normal;
}

.pagination-first, .pagination-last {
    @apply capitalize hover:text-colorPrimary mx-1 duration-300 cursor-pointer;
}

.pagination-disabled{
    @apply pointer-events-none opacity-50;
}

.pagination-previous, .pagination-next {
    @apply hover:text-colorPrimary duration-300 cursor-pointer rounded-sm;
}

.pagination-current {
    @apply flex items-center gap-2 mx-0;
}

.pagination-current span:nth-child(2) {
    @apply flex items-center gap-2 px-3 py-1 border-[0.5px] border-colorDivider rounded-sm;
}