/* Category main page */
.category-blogs-heading {
    @apply flex md:flex-row flex-col justify-between border-b-2 border-colorBorder;
}

.category-blogs-link {
    @apply flex  items-center md:mt-0 mt-2 text-[16px] lg:text-[16px] xl:text-[20px] capitalize text-colorPrimary hover:text-colorSecondary font-bold ease-linear duration-100;
}

.category-blogs-content {
    @apply md:my-4 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 lg:gap-8 xxl:gap-8;
}