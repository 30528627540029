/* Editor css */
.editor{
    @apply mb-4;
}

.editor .ql-toolbar {
    @apply bg-colorWhite;
}

.editor .ql-container {
    @apply bg-colorWhite h-[280px] xl:h-[380px] text-[14px] xl:text-[16px] font-normal;
}