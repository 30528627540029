/* Rating popup */
.rating-wrapper {
    @apply fixed w-[100%] h-[100%] z-40 top-0 left-0 flex justify-center items-center bg-colorBgModal;
}

.rating-content {
    @apply relative opacity-0 bg-colorWhite min-w-[85%] md:min-w-[28%] flex flex-col gap-4 xl:gap-6 justify-center items-center py-7 md:py-8 rounded-md fade-in;
}

.rating-close-button {
    @apply absolute top-2 right-2 text-[20px] xl:text-[22px] font-bold hover:text-colorDanger cursor-pointer duration-300;
}

.rating-title {
    @apply text-[20px] xl:text-[24px] capitalize fade-in;
}

.rating-stars {
    @apply flex gap-2.5 xl:gap-3 text-[30px] xl:text-[36px];
}

.rating-submit {
    @apply flex flex-col items-center gap-4 mt-2;
}

.rating-loader {
    @apply flex gap-2 items-center text-[18px] xl:text-[20px] capitalize;
}

.rating-loader-icon {
    @apply text-[24px] xl:text-[26px] text-colorPrimary animate-spin;
}

.rating-btn-submit {
    @apply bg-colorPrimary hover:bg-colorSecondary text-[14px] xl:text-[16px] text-colorTextWhite uppercase px-4 py-2 rounded-md duration-300;
}