/* Contact section */
.contact {
    @apply w-full relative flex md:flex-row flex-col h-[90vh] justify-center;
}

.contact-text {
    @apply flex flex-none gap-2 md:basis-2/5 xl:basis-0 md:flex-1 flex-col justify-center items-center mt-0 md:mt-4;
}

.contact-text-heading {
    @apply text-colorPrimary uppercase tracking-wide text-[24px] md:text-[26px] lg:text-[32px] xl:text-[38px] xxl:text-[50px] font-semibold font-sans;
}

.contact-text-subheading {
    @apply text-colorBlack xl:text-[20px] lg:text-[17px];
}

.contact-form-container {
    @apply flex flex-none md:basis-3/5 xl:basis-0 md:flex-1 justify-center md:justify-end items-center my-10 md:my-0;
}

.contact-form {
    @apply flex flex-col w-full sm:w-[500px] md:w-[95%] lg:w-[70%] xl:w-[85%] xxl:w-[95%] bg-colorWhite px-6 py-12 lg:px-10 lg:py-12 xl:px-12 xl:py-14 xxl:py-16 rounded-lg;
}

.contact-form-bg-img1 {
    @apply absolute -z-10 hidden md:block md:left-0 md:top-[5%] left-[-5%] top-[15%] lg:w-[15%] md:w-[25%] motion;
}

.contact-form-bg-img2 {
    @apply absolute -z-10 hidden md:block md:left-[35%] md:bottom-[5%] right-[-5%] bottom-[3%] lg:w-[15%] md:w-[25%] motion-reverse;
}

.contact-submit-container {
    @apply w-full lg:w-[21%] xl:w-[20%];
}