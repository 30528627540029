/* Newsletter */
.newsletter-container {
    @apply w-full bg-colorTertiary flex md:flex-row flex-col min-h-[350px] md:min-h-[450px] xl:min-h-[500px];
}

.newsletter-left {
    @apply md:flex hidden flex-1 justify-start items-center;
}

.newsletter-left img {
    @apply w-[200%] ;
}

.newsletter-right {
    @apply flex flex-1 flex-col justify-center items-center;
}

/* .newsletter-right {
    clip-path: polygon(23% 0, 100% 0%, 100% 100%, 0% 100%);
} */

.newsletter-right p {
    @apply capitalize text-[16px] xl:text-[18px] font-medium;
}

.newsletter-right h1 {
    @apply bg-colorSecondary px-6 py-1 text-colorTextWhite -skew-x-12;
}

.newsletter-right :nth-child(3) {
    @apply mt-6 text-[14px] xl:text-[16px] font-light;
}
.newsletter-right form {
    @apply w-[95%] lg:w-[65%] flex flex-col mt-12;
}

.newsletter-input-group {
    @apply flex;
}

.newsletter-input{
    @apply w-full text-[14px] xl:text-[16px] font-light px-4 py-3 xl:px-6 xl:py-4 rounded-tl-md rounded-bl-md ;
}

.newsletter-input::placeholder {
    @apply text-[14px] xl:text-[16px] font-light capitalize;
}

.newsletter-input:focus {
    @apply outline-none;
}

.newsletter-submit {
    @apply px-4 lg:px-5 xl:px-6 bg-gradient-to-t from-colorPrimary to-colorSecondary hover:from-colorSecondary hover:to-colorSecondary text-colorWhite text-[15px] xl:text-[16px] font-sans capitalize rounded-tr-md rounded-br-md duration-500 !important;
}

.newsletter-agreement {
    @apply min-w-[100%] md:min-w-[55%] lg:min-w-[35%] xl:min-w-[32%] flex items-center gap-2 mt-6;
}

.newsletter-agreement p {
    @apply w-[100%] text-[13px] xl:text-[15px] font-extralight;
}

.newsletter-agreement a {
    @apply text-colorPrimary hover:underline hover:text-colorSecondary font-semibold duration-300;
}