
.form-input-container {
    @apply relative mb-2 lg:mb-3 xl:mb-4;
}

.form-input {
    @apply w-full px-8 md:px-10 xl:px-11 py-3 lg:py-2.5 xl:py-3 xxl:py-4 text-[14px] xl:text-[16px] border-[1.5px] border-colorBorder ease-linear font-normal;
}

.form-input-select {
    @apply px-4 mt-4;
}

.form-input:focus {
    @apply outline-none;
}

.form-input::placeholder {
    @apply text-[14px] xl:text-[16px] font-light capitalize;
}

.form-input:focus + .form-input-icon {
    @apply opacity-100 text-colorPrimary;
}

.form-input:focus + .form-input-icon-start {
    @apply opacity-100 text-colorPrimary;
}

.form-input-icon {
    @apply absolute left-2 md:left-3 top-0 bottom-0 my-auto xl:text-[22px] text-[18px] opacity-40 ease-linear duration-200;
}

.form-input-icon-start {
    @apply absolute left-2 md:left-3 top-3.5 bottom-0 xl:text-[22px] text-[18px] opacity-40 ease-linear duration-200;
}