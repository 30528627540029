/* Navbar mega menu section */
.navbar-mega-menu {
    @apply md:flex hidden w-full h-[100vh] gap-10 mt-4;
}

.navbar-mega-menu-topics {
    @apply flex flex-col md:items-start items-center lg:w-[25%] w-[35%];
}

.navbar-mega-menu-topics-heading {
    @apply absolute;
}

.navbar-mega-menu-topics-tag {
    @apply text-colorTextWhite cursor-pointer;
}

.navbar-mega-menu-topics-tag-border {
    @apply relative border-2 border-colorSecondary px-8 py-1 lg:px-14 xl:px-16 rounded-sm xl:text-[18px] lg:text-[14px] text-[14px] font-light uppercase tracking-wider;
}

.navbar-mega-menu-topics-tag-text {
    @apply absolute lg:left-[-6px] lg:top-[-6px] left-[-4px] top-[-4px] bg-colorSecondary px-3 py-1 md:px-8 lg:px-14 xl:px-16 rounded-sm uppercase font-medium;
}

.navbar-mega-menu-topics-list {
    @apply flex flex-col xl:gap-6 lg:gap-5 gap-4 xl:mt-[70px] lg:mt-[65px] mt-[55px];
}

.navbar-mega-menu-topics-list-item {
    @apply flex items-center hover:text-colorPrimary font-semibold cursor-pointer xl:text-[24px] lg:text-[20px] text-[18px] ease-linear duration-200;
}

.navbar-mega-menu-topics-list-item-active {
    @apply text-colorPrimary ease-linear duration-200;
}

.navbar-mega-menu-content {
    @apply relative flex flex-wrap gap-6 lg:w-[75%] w-[65%] flex-col;
}

.navbar-mega-menu-content-blogs {
    @apply grid lg:grid-cols-2 grid-cols-1 xl:gap-6 lg:gap-3 gap-4 mt-[75px];
}

.navbar-mega-menu-content-category-tag {
    @apply absolute text-colorTextWhite font-sans;
}

.navbar-mega-menu-content-link {
    @apply absolute right-0 text-[18px] capitalize font-sans;
}

.navbar-mega-menu-content-category-tag-border {
    @apply relative border-2 border-colorSecondary px-3 py-1 md:px-4 md:py-2 lg:px-5 xl:px-6 rounded-sm xl:text-[18px] text-[14px] uppercase font-medium;
}

.navbar-mega-menu-content-category-tag-text {
    @apply absolute lg:left-[-8px] lg:top-[-8px] left-[-5px] top-[-6px]  bg-colorSecondary px-3 py-1 md:px-4 md:py-2 lg:px-5 xl:px-6 rounded-sm uppercase font-medium;
}

.navbar-mega-menu-content-blogs {
    @apply lg:mt-[10%] mt-[12%];
}

/* Navbar mega menu small devices */
.navbar-mega-menu-sm {
    @apply md:hidden flex w-full justify-center text-center py-5;
}
.navbar-mega-menu-sm-topics-heading {
    @apply absolute;
}

.navbar-mega-menu-sm-topics-tag {
    @apply text-colorTextWhite cursor-pointer;
}

.navbar-mega-menu-sm-topics-tag-border {
    @apply relative border-2 border-colorSecondary px-10 py-2 text-[18px] font-light uppercase tracking-wider;
}

.navbar-mega-menu-sm-topics-tag-text {
    @apply absolute lg:left-[-6px] lg:top-[-6px] left-[-4px] top-[-4px] bg-colorSecondary px-10 py-2 rounded-sm uppercase font-medium;
}

.navbar-mega-menu-sm-topics-links {
    @apply flex flex-col sm:mt-[20%] mt-[30%] gap-10;
}

.navbar-mega-menu-sm-topics-link {
    @apply uppercase;
}

.recent-blogs-heading {
    @apply flex md:flex-row flex-col justify-between border-b-2 border-colorBorder;
}

.recent-blogs-content {
    @apply md:my-4 flex md:flex-row flex-col xxl:gap-10 xl:gap-6 lg:gap-5 gap-4;
}

.recent-blogs-content-main {
    @apply md:w-[45%] w-full md:mb-0 mb-2;
}

.recent-blogs-content-others {
    @apply md:w-[55%] w-full grid md:grid-cols-2 grid-cols-1 xxl:gap-10 xl:gap-6 lg:gap-5 gap-4;
}