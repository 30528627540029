/* Custom select */
.custom-select {
	width: 100%;
	position: relative;
	margin-top: 20px;
}

.custom-select-selected {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #ffffff !important;
	border-bottom: 2px solid rgba(224, 224, 224, 0.5);
	padding-left: 45px !important;
	padding-right: 15px !important;
	cursor: pointer;
	text-transform: capitalize;
}

.custom-select-dropdown-icon {
	transform: rotate(0deg);
	transition: 400ms ease-in-out;
	font-size: 1.3rem;
}

.custom-select-dropdown-icon-active {
	transform: rotate(-90deg);
	transition: 400ms ease-in-out;
	font-size: 1.3rem;
}

.custom-select-dropdown-options {
	position: absolute;
	top: 100%;
	left: 0;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 2px solid rgba(224, 224, 224, 0.5);
	padding-left: 15px !important;
	padding-right: 15px !important;
	z-index: 999;
	transition: 0.2s linear;
}

.custom-select-dropdown-options-hidden {
	top: 120%;
	opacity: 0;
	pointer-events: none;
}

.custom-select-dropdown-options span{
	width: 100%;
	cursor: pointer;
	padding: 10px 5px;
	transition: 100ms ease-in-out;
	border-radius: 5px
}

.custom-select-dropdown-options span:hover{
	background-color: rgb(55, 147, 166, 0.9);
	transition: 100ms ease-in-out;
	color: #f5f5f5;
}