/* Navbar section */
.navbar {
    @apply flex z-20 sticky top-0 items-center bg-[#f5f5f5] px-4 md:px-12 py-4 md:py-6 xl:py-8 ease-linear duration-100;
}

.navbar-sticky {
    @apply md:py-3 xl:py-4 xxl:py-5 z-30 ease-linear duration-100;
}

.navbar-logo {
    @apply flex flex-1 text-colorPrimary;
}

.navbar-filter {
    @apply flex md:flex-none flex-1 md:justify-center md:mr-0 mr-4 justify-end gap-2 sm:gap-6 lg:gap-10 md:gap-8 text-[16px] md:text-[14px] xl:text-[18px] uppercase tracking-wider leading-normal font-semibold cursor-pointer;
}

.navbar-filter-title {
    @apply flex items-center hover:text-colorPrimary ease-linear duration-100;
}

.navbar-links {
    @apply hidden md:flex lg:ml-10 md:ml-8 items-center justify-end tracking-wider leading-normal font-semibold;
}

.navbar-links-list {
    @apply mr-8 xl:mr-10 last-of-type:mr-0;
}

.navbar-link {
    @apply hover:text-colorPrimary text-[16px] md:text-[14px] xl:text-[18px] uppercase ease-linear duration-100;
}

.navbar-links-toggle {
    @apply flex md:hidden flex-none items-center z-10;
}

.navbar-links-toggle-icon-menu {
    @apply text-[32px] text-colorPrimary ease-linear duration-100 cursor-pointer;
}

.navbar-links-toggle-icon-close {
    @apply text-[32px] text-colorDanger ease-linear duration-100 cursor-pointer;
}

.navbar-links-toggle-links {
    @apply flex w-[100%] h-auto fixed left-0 bottom-0 tracking-wide bg-gradient-to-bl from-colorPrimary to-colorSecondary ease-in-out duration-700;
}

.navbar-links-toggle-links-hidden {
    @apply left-0 bottom-[-100%] ease-in-out duration-700;
}

.navbar-links-toggle-link {
    @apply flex flex-1 flex-col items-center justify-center py-1.5 uppercase text-colorTextWhite text-[12px] border-r-[0.5px] border-r-colorBorder last-of-type:border-r-0;
}

.navbar-links-toggle-link-icon {
    @apply text-[25px];
}
.navbar-mega-menu-container {
    @apply bg-colorTertiary z-30 fixed w-[100%] h-[100%] ease-linear duration-300;
}

.navbar-mega-menu-active {
    @apply flex left-0 fade-in ease-linear duration-300;
}

.navbar-mega-menu-hide {
    @apply left-[-100%] fade-out ease-linear duration-300;
}
/* Navbar search */
.search-container {
    @apply flex flex-col items-start fixed top-0 md:right-[-540px] right-[-100%] z-40 xl:w-[540px] lg:w-[480px] md:w-[450px] w-[100%] h-[100vh] bg-colorBlack ease-linear duration-300;
}

.search-container-header {
    @apply flex flex-none flex-col w-full md:mt-6 mt-5;
}

.search-container-active {
    @apply right-0;
}

.search-close-btn {
    @apply absolute top-[20px] right-[20px] text-colorTextWhite cursor-pointer xl:text-[40px] lg:text-[36px] md:text-[32px] text-[36px] hover:text-colorAccent ease-linear duration-200;
}

.search-heading {
    @apply mt-6 lg:mb-4 md:mb-2 mb-4 text-colorTextWhite xl:text-[28px] lg:text-[24px] md:text-[22px] text-[24px] font-medium;
}

.search-form {
    @apply md:w-full sm:w-[80%] w-full flex items-center;
}

.search-form-input {
    @apply grow xl:h-[60px] lg:h-[55px] md:h-[50px] h-[60px] px-4 py-3 xxl:py-4 text-[18px] md:text-[15px] lg:text-[16px] xl:text-[18px] font-light rounded-tl-sm rounded-bl-sm;
}

.search-form-input:focus {
    @apply outline-none;
}

.search-form-input::placeholder {
    @apply text-[18px] md:text-[15px] lg:text-[16px] xl:text-[18px] font-light capitalize;
}

.search-btn-submit {
    @apply xl:h-[60px] lg:h-[55px] md:h-[50px] h-[60px] px-4 py-3 xxl:py-4 xl:text-[18px] lg:text-[16px] md:text-[15px] text-[18px] capitalize rounded-tr-sm rounded-br-sm text-colorWhite bg-gradient-to-r from-colorPrimary to-colorSecondary hover:from-colorSecondary hover:to-colorSecondary ease-linear duration-300 !important;
}

.search-trending-heading {
    @apply flex items-center gap-1 text-colorTextWhite xl:text-[18px] md:text-[16px] text-[18px] font-semibold uppercase border-b-2 border-colorTextWhite border-opacity-10 mt-6 mb-2;
}

.search-trending-icon {
    @apply text-colorSecondary animate-pulse;
}

.search-trending-content {
    @apply flex flex-auto flex-col xl:my-4 md:my-3 my-5 pr-2 w-full text-colorTextWhite overflow-auto;
}

.search-trending-content::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.search-trending-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgb(128, 128, 128, 0.6);
    border-radius: 10px;
}

/* Handle */
.search-trending-content::-webkit-scrollbar-thumb {
    background: rgba(55, 147, 166, 0.8);
    border-radius: 10px;
}

.search-trending-thumbnails {
    @apply flex flex-col xl:gap-6 lg:gap-4 gap-5;
}
