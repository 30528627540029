/* Privacy Policy Section */
.policy-page {
    @apply w-full;
}

.policy-page-banner {
    @apply relative flex lg:flex-row flex-col-reverse h-[88vh];
}

.policy-page-banner-left {
    @apply flex flex-1 flex-col md:justify-center justify-start md:gap-5 gap-3;
}

.policy-page-banner-heading {
    @apply relative w-auto xl:text-[65px] lg:text-[55px] md:text-[40px] text-[28px] tracking-[2px]  uppercase font-sans font-bold fade-in-bottom;
}

.policy-page-banner-heading-line {
    @apply absolute lg:bottom-0 left-0 bottom-[-5px] lg:w-[50%] md:w-[25%] w-[50%] h-[2px] skew-x-[-30deg] bg-colorTertiary opacity-100;
}

.policy-page-banner-description {
    @apply lg:max-w-[90%] w-full text-justify;
}

.policy-page-banner-description span {
    @apply text-colorPrimary font-semibold;
}

.policy-page-banner-right {
    @apply flex flex-1 justify-center items-center lg:mt-0 md:mt-[10%];
}

.policy-page-banner-right-img-container {
    @apply relative xl:w-[95%] lg:w-[92%] md:w-[90%] w-[100%];
}

.policy-page-banner-right-img-laptop {
    @apply w-[100%];
}

.policy-page-banner-right-img-shield-container {
    @apply absolute right-0 bottom-[20%] xl:w-[30%] w-[28%];
}

.policy-page-banner-right-img-shield {
    @apply w-[100%];
}

.policy-page-banner-right-img-lock-container {
    @apply absolute right-0 xl:bottom-[40%] bottom-[38%] xl:w-[30%] w-[28%];
}

.policy-page-banner-right-img-lock {
    @apply w-[32%] mx-auto pulsate-bck;
}

.policy-page-banner-right-img-circle-container {
    @apply absolute z-20 right-0 bottom-[20%] xl:w-[30%] w-[28%];
}

.policy-page-banner-right-img-circle {
    @apply w-[100%];
}

.policy-page-banner-right-img-cookie-container {
    @apply absolute z-20 right-0 bottom-[27%] xl:w-[30%] w-[28%];
}

.policy-page-banner-right-img-cookie {
    @apply w-[75%] mx-auto rotate;
}

.policy-page-banner-right-img-browser-container {
    @apply absolute z-10 top-[-10%] left-[10%]  xl:w-[280px] xl:h-[280px] lg:w-[230px] lg:h-[230px] md:w-[260px] md:h-[260px] w-[150px] h-[150px] rounded-[50%];
}

.policy-page-banner-right-img-browser4-container {
    @apply absolute z-10 top-[-20%] right-[10%]  xl:w-[280px] xl:h-[280px] lg:w-[230px] lg:h-[230px] md:w-[260px] md:h-[260px] w-[150px] h-[150px] rounded-[50%];
}

.policy-page-banner-right-img-browsers {
    @apply relative w-[100%] h-[100%] rounded-[50%];
}

.policy-page-banner-right-img-browser1 {
    @apply absolute left-0 top-0 right-0 bottom-0 xl:w-[40%] lg:w-[40%] w-[40%]  m-auto pulsate-bck;
}

.policy-page-banner-right-img-browser2 {
    @apply absolute left-0 top-0 right-0 bottom-0 m-auto xl:w-[45%] lg:w-[45%] w-[45%] circular-animation1;
}

.policy-page-banner-right-img-browser3 {
    @apply absolute left-0 top-0 right-0 bottom-0 m-auto xl:w-[45%] lg:w-[45%] w-[45%] circular-animation2;
}

.policy-page-banner-right-img-browser4 {
    @apply absolute left-0 top-0 right-0 bottom-0 m-auto xl:w-[90%] lg:w-[85%] w-[80%];
}

#policy-page-banner-btn-target {
    @apply absolute left-0 sm:bottom-[50px] bottom-[100px] opacity-0;
}

.policy-page-content {
    @apply flex flex-col gap-10 pb-4 lg:pb-6 xl:pb-8 xxl:pb-14;
}

.policy-page-content-section {
    @apply flex flex-col lg:gap-8 gap-7;
}

.policy-page-content-heading-tag {
    @apply text-colorTextWhite font-sans;
}

.policy-page-content-heading-tag-border {
    @apply relative border-2 border-colorSecondary px-3 py-2 md:px-4 md:py-2 lg:px-6 rounded-sm xl:text-[17px] text-[15px] uppercase;
}

.policy-page-content-heading-tag-text {
    @apply absolute lg:left-[-8px] lg:top-[-8px] left-[-7px] top-[-8px]  bg-colorSecondary xl:text-[17px] text-[15px] px-3 py-2 md:px-4 md:py-2 lg:px-6 rounded-sm uppercase font-semibold;
}

.policy-page-content-text {
    @apply flex flex-col gap-4;
}

.policy-page-content-text-list {
    @apply flex flex-col;
}

.policy-page-content-text-list span {
    @apply font-bold uppercase xl:text-[16px] text-[15px];
}

.policy-page-content-text-list-description {
    @apply text-[17px] xl:text-[19px] xxl:text-[20px] font-medium tracking-normal leading-[26px] md:leading-[25px] xl:leading-[26px] -mt-2;
}

.policy-page-content-text-bullets {
    @apply flex flex-col;
}

.policy-page-content-text-bullet {
    @apply flex md:ml-4 ml-0;
}

.policy-page-content-text-bullet-symbol {
    @apply flex items-start pt-[6px] mr-2 xl:text-[18px] text-[17px];
}