/* Dashboard password change */
.dashboard-password-change-wrapper {
    @apply w-full flex flex-col gap-10;
}

.dashboard-password-change-heading {
    @apply flex items-center gap-3;
}

.dashboard-password-change-heading-icon {
    @apply text-colorSecondary xl:text-[28px] md:text-[24px] text-[26px];
}

.dashboard-password-change-content {
    @apply flex flex-col gap-6
}