/* Search result section*/
.search-result {
    @apply min-h-[88vh] flex flex-col gap-8;
}

.search-result-heading {
    @apply flex md:flex-row flex-col md:gap-0 gap-2 justify-between font-sans font-light capitalize py-4 xl:text-[18px] md:text-[16px] text-[18px];
}

.search-result-heading span {
    @apply font-semibold uppercase text-colorPrimary;
}

.search-result-content {
    @apply md:my-4 grid md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 xl:gap-12 xxl:gap-14;
}