/* Login section */
.login {
    @apply flex justify-center items-center w-full h-[100vh];
}

.login-bg-img {
    @apply fixed -z-10 mx-auto xl:w-[52%] lg:w-[50%] md:w-[70%] w-[100%];
}

.login-form {
    @apply flex flex-col relative w-full sm:w-[350px] lg:w-[380px] xl:w-[420px] xxl:w-[500px] bg-colorWhite px-7 py-12 lg:px-8 lg:py-12 xl:px-10 xl:py-14 rounded-lg img-shadow;
}

.login-title {
    @apply flex justify-center items-center absolute w-[100%] px-6 py-3 left-0 top-[-30px] mb-6 text-colorTextWhite xxl:text-[20px] xl:text-[18px] text-[17px] font-medium uppercase bg-colorPrimary rounded-t-lg;
}

.login-icon {
    @apply mr-2;
}

.login-submit-container {
    @apply w-full mt-1;
}

.login-footer {
    @apply fixed lg:top-[30px] top-[20px] xl:left-[50px] lg:left-[40px] left-[20px] flex justify-center items-center uppercase font-normal xl:text-[18px] text-[16px];
}

.login-footer-link {
    @apply flex items-center hover:text-colorAccent ease-linear duration-200;
}
