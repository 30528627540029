/* Create new blog */
.dashboard-category-create-wrapper {
    @apply w-full flex flex-col gap-8;
}

.dashboard-category-create-heading {
    @apply flex items-center gap-3;
}

.dashboard-category-create-heading-icon {
    @apply text-colorSecondary xl:text-[28px] md:text-[24px] text-[26px];
}

.category-image-input-container{
    @apply relative w-full flex flex-col h-auto my-4 justify-start items-start transition ease-in-out delay-150 duration-500;
}

.category-image-input-error{
    @apply flex w-full;
}

.category-image-input {
    @apply w-full flex lg:flex-row flex-col flex-1 items-start lg:items-center;
}

.category-image-input-preview {
    @apply w-full flex flex-1 lg:justify-center justify-start items-center mt-2;
}

.category-image-input-preview img{
    @apply max-w-[200px] h-[120px] rounded-md nav-shadow;
}

.category-image-input-preview p {
    @apply font-medium xl:text-[20px] text-[16px] capitalize;
}